/** @format */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
}
@font-face {
  font-family: "eurostileunicaseltpro";
  src: url(./font/eurostileunicaseltpro-maisfontes.2928/eurostileunicaseltpro.otf);
}
p {
  font-family: "Inter", sans-serif !important;
  margin-bottom: 0px !important;
}
.font-para {
  font-family: "Inter", sans-serif !important;
}
.font-head {
  font-family: "eurostileunicaseltpro" !important;
}

::selection {
  background: #fff;
  color: #000;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  background-color: #000;
}
a {
  text-decoration: none !important;
}
body {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  overflow-x: hidden !important;
  position: relative !important;
  background: linear-gradient(
    89deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(87, 84, 108, 0.47) 20%,
    rgba(87, 84, 108, 0.54) 81%,
    rgba(255, 255, 255, 1) 100%
  );
}
.navbar {
  background: rgba(7, 7, 7, 0.75);
  /* Neutral/Neutral 01 */

  border-bottom: 1px solid #2c3a4b;
  backdrop-filter: blur(25px);
}

.navbar-toggler {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding-right: 20px;
}

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none !important;
}
.navbar-toggler-icon {
  width: 24px !important;
  height: 17px !important;
  background-image: none !important;
  position: relative !important;
  border-bottom: 3px solid #fff !important;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px !important;
  position: absolute !important;
  height: 2px !important;
  background-color: #fff !important;
  top: 0 !important;
  left: 0 !important;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after {
  top: 8px !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}

.navbar {
  background-color: #00000035 !important;
  // backdrop-filter: blur(5px) !important;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.726) !important;
  z-index: 1;
}
.nav-link {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 170%;
  transition: all 0.4s;
  position: relative;
  display: block;
  color: #6d7580 !important;
  letter-spacing: 0.2px !important;
}
.nav-link:hover {
  color: #a3a3aef1 !important;
}

.collapse {
  visibility: visible !important;
}
.card_main {
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-repeat: no-repeat;
  background-position: left top;
  background-size: auto 100%;
  flex: 0 0 380px;
  width: 380;
  margin: 0px 10px 107px;
  padding: 32px 22px 10px 22px;
  background: linear-gradient(95.64deg, #57546c4d -117.13%, #f1f1f877 89.73%),
    url("../src/media/images/border.svg") no-repeat !important;
  border-radius: 24px;
}
.i::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  opacity: 20%;
  background: white;
  /*   background: #A3A3AE; */
  /* Centering */
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.i:hover:before {
  animation: anim-in 0.7s forwards ease-out;
}

@keyframes anim-in {
  100% {
    opacity: 0%;
    border-radius: 0;
    width: 600px;
    height: 600px;
  }
  0% {
    width: 0px;
    height: 0px;
    border-radius: 100%;
    opacity: 20%;
  }
}
@keyframes appear-slide-up {
  0% {
    transform: translateY(32px);
  }

  to {
    transform: translateY(0);
  }
}
.animate-appear-slide-up {
  animation: appear-slide-up 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}
.codes {
  position: relative;
  height: 40px;
  border-radius: 40px;
  width: 50%;
  background-color: rgba(0, 0, 0, 0.582);
  padding: 10px 10px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  color: #fff !important;
  justify-content: center;
  cursor: pointer;
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.16);
  user-select: none;
  pointer-events: initial;
}
.animate-timer {
  position: relative;
  animation: animatetop 1s infinite;
}
@keyframes animatetop {
  from {
    top: -50px;
  }
  to {
    top: 20px;
  }
}
.ref {
  position: relative;
  height: 40px;
  border-radius: 40px;
  width: 50%;
  background-color: rgba(0, 0, 0, 0.24);
  padding: 10px 10px;
  -webkit-box-align: center;
  font-weight: bold;
  align-items: center;
  -webkit-box-pack: center;
  color: #000 !important;
  justify-content: center;
  cursor: pointer;
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.16);
  user-select: none;
  pointer-events: initial;
}
@media only screen and (max-width: 600px) {
  .codes {
    width: 80%;
  }
  .Referred {
    width: 80%;
  }
}
